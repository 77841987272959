import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SuggestionApi from '../../apis/suggestion';

export interface ISuggestionState {
  suggestionList: any | null;
  suggestionDetail: any | null;
  selected: any;
}

const initialState: ISuggestionState = {
  suggestionList: null,
  suggestionDetail: null,
  selected: null,
};

export const fetchSuggestion = createAsyncThunk(
  'suggestions/fetch-suggestions',
  async (page?: number) => {
    const { data: response } = await SuggestionApi.getSuggestionList(page);
    return response;
  }
);

export const fetchSuggestionDetail = createAsyncThunk(
  'suggestions/fetch-suggestion-detail',
  async (id: number) => {
    const { data: response } = await SuggestionApi.getSuggestionDetail(id);
    return response;
  }
);

export const suggestionSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    setSuggestionList: (state, action) => {
      state.suggestionList = action.payload;
    },
  },
  extraReducers: {
    [fetchSuggestion.fulfilled as any]: (state, action) => {
      state.suggestionList = action.payload;
    },
    [fetchSuggestionDetail.fulfilled as any]: (state, action) => {
      state.suggestionDetail = action.payload;
    },
  },
});

export const { setSelected, setSuggestionList } = suggestionSlice.actions;

export default suggestionSlice.reducer;
