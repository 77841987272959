import React, { useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import Routes from './routes';
import themeList from './theme';
import { useAppDispatch, useAppSelector } from './hooks';
import { initI18n } from './utils/locales/i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'suneditor/dist/css/suneditor.min.css';

function App() {
  const {
    theme,
    locale: { user, admin },
  } = useAppSelector((state) => state.global);
  const pathname = window.location.pathname;
  const isAdmin = React.useMemo(() => pathname.includes('/admin'), [pathname]);

  initI18n(isAdmin ? admin : user);
  return (
    <MuiThemeProvider theme={themeList[theme]}>
      <ThemeProvider theme={themeList[theme]}>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
