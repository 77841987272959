import IRoute from '../models/Route';
import async from '../components/Async';
import AuthLayout from '../layouts/AuthLayout';
import { Routes } from '../constants';

const Page404 = async(() => import('../pages/auth/Page404'));
const Login = async(() => import('../pages/auth/Login'));
const ForgotPassword = async(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = async(() => import('../pages/auth/ResetPassword'));
const Register = async(() => import('../pages/auth/Register'));
const RegisterConfirm = async(() => import('../pages/auth/RegisterConfirm'));
const RunSurvey = async(() => import('../pages/RunSurvey'));

const authRoutes: IRoute[] = [
  {
    id: 'HomePage',
    path: '/auth/404',
    component: Page404,
    children: null,
  },
  {
    id: 'Login',
    path: '/login',
    component: Login,
    children: null,
    layout: AuthLayout,
  },
  {
    id: 'ForgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    children: null,
    layout: AuthLayout,
  },
  {
    id: 'Register',
    path: '/register',
    component: Register,
    children: null,
    layout: AuthLayout,
  },
  {
    id: 'RegisterConfirm',
    path: '/register-confirm/:userToken',
    component: RegisterConfirm,
    children: null,
    layout: AuthLayout,
  },
  {
    id: 'ResetPassword',
    path: '/reset-password/:token',
    component: ResetPassword,
    children: null,
    layout: AuthLayout,
  },
  {
    id: 'RunSurvey',
    path: `${Routes.RUN_SURVEY}/:surveyToken`,
    component: RunSurvey,
  },
];

export default authRoutes;
