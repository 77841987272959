export default {
  title: 'English title',
  locale: {
    en: 'English',
    th: 'Thailand',
  },
  menu: {
    home: 'Home',
    balance: 'Balance',
    careShop: 'Care Shop',
    profile: 'Profile',
  },
  common: {
    error: 'Error',
    adminSection: 'Admin Section',
    logout: 'Logout',
    filter: 'Filter',
    reset: 'Reset',
    exportAsCsv: 'Export as CSV',
    male: 'Male',
    female: 'Female',
    rowsPerPage: 'Rows per page',
    of: 'of',
    startDate: 'Start Date',
    endDate: 'End Date',
    update: 'Update',
    cancel: 'Cancel',
    editSuccessfully: 'Edit successfully!',
    buyNow: 'BUY NOW',
    startFreeTrial: 'START FREE TRIAL',
    oneMonth: '1 month',
    moreDetail: 'More Detail',
    next: 'Next',
    goBackToHomepage: 'GO BACK TO HOMEPAGE',
    confirm: 'CONFIRM',
    no: 'NO',
    bahtPerYear: 'THB/year',
    bahtPerMonth: 'THB/month',
  },
  page: {
    home: {
      products: 'Products',
      services: 'Services',
    },
    checkoutRejection: { refuseInsurance: 'Refuse Insurance' },
    thankYou: {
      dear: 'Dear {{name}},',
      thankYouText:
        'Thank you for choosing rabbit finance. Find below the summary and next steps for your policy underwriting:',
      thankYouTextFreeTrial:
        'Thank you, your 30 day free trial started. Find below the summary and next steps.',
      showCoverageDetail: 'Show coverage detail',
      nextSteps: 'Next steps',
      totalPrice: 'Total Price',
      beCovered: 'Be Covered',
      receivePolicy: 'Receive Policy',
      textIncludeTax: '*This price includes 7% VAT and stamp duty',
      goBackToHomepage: 'GO BACK TO HOMEPAGE',
    },
    serviceDetail: {
      time: 'time',
    },
    productDetail: {
      coverage: 'Coverage',
      condition: 'Condition',
    },
    healthQuestions: {
      healthQuestions: 'Health questions',
    },
    checkout: {
      checkout: 'Check out',
      personalInformation: 'Personal Information',
      title: 'Title',
      name: 'Name',
      surname: 'Surname',
      idCardNumber: 'ID Card Number',
      phoneNumber: 'Phone number',
      emailAddress: 'Email Address',
      dateOfBirth: 'Date of Birth',
      address: 'Address',
      province: 'Province',
      district: 'District',
      subDistrict: 'Sub-district',
      postcode: 'Postcode',
      additionalQuestion: 'Additional Question',
      weightKg: 'Weight (kg)',
      heightCm: 'Height (Cm)',
      occupation: 'Occupation',
      subOccupation: 'Sub-occupation',
      confirm: 'CONFIRM',
      addCardDetails: 'Add Card Details',
      selectCard: 'Select Card',
      newCard: 'New card',
      cardNo: 'Card No.',
      nameOfCardHolder: 'Name of card holder',
      expiryDate: 'Expiry Date (MM/YYYY)',
      cvv: 'CVV',
      saveCardDetails: 'Save card details',
      setToDefault: 'Set to default',
      shortDetails:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    checkoutResult: {
      paymentUnsuccessful: 'The Payment was Unsuccessful',
      paymentUnsuccessfulSubtitle:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    },
    consent: {
      consent: 'Consent',
    },
    lastCoverage: {
      lastCoverageDate: 'Last Coverage Date',
      description: 'Please provide your policy last coverage date?',
      placeHolderDateField: 'Please select your last coverage date',
    },
    confirmCancel: {
      confirmCancellation: 'Confirm Cancellation',
      description:
        'Please find below your refund amount calculated based on your last coverage date.',
    },
    cancellationSuccessful: {
      dear: 'Dear {{name}},',
      cancelText:
        'Your policy is cancelled. Please find your policy cancellation details below.',
    },
    currentCoverages: {
      pageTitle: 'Current Coverages',
      confirmPolicy:
        'By cancelling this policy, you will not get the coverages below.',
      confirmSection: 'Do you want to confirm cancellation?',
    },
    cancellationReasons: {
      title: 'Cancellation Reasons',
      reason: 'Why are your cancelling?',
      reasonDetail: 'Please provide the detail',
      placeHolderCombobox: 'Please tell us what happened',
      placeHolderTextField: 'Please Tell Us',
    },
    mySubscriptions: {
      yourPurchase: 'Your Purchase',
      mySubscriptions: 'My Subscriptions',
      myCancelledProducts: 'My Cancelled Products',
      product: 'Product',
      status: 'Status',
      nextPayment: 'Next Payment',
      total: 'Total',
      renew: 'RENEW',
      view: 'VIEW',
    },
    mySubscriptionDetail: {
      status: 'Status',
      policyNumber: 'Policy Number',
      PAPolicy: 'PA Policy',
      healthPolicy: 'Health Policy',
      startCoverageDate: 'Start Coverage Date',
      endCoverageDate: 'End Coverage Date',
      lastPaymentDate: 'Last Payment Date',
      nextPaymentDate: 'Next Payment Date',
      total: 'Total',
      refund: 'Refund Amount',
      cancel: 'CANCEL',
    },
  },
  admin: {
    product: {
      productManagement: 'Product Management',
      id: 'ID',
      productId: 'Product ID',
      image: 'Image',
      name_en: 'English Name',
      name_th: 'Thai Name',
      short_desc_en: 'English Short Description',
      short_desc_th: 'Thai Short Description',
      description_en: 'English Description',
      description_th: 'Thai Description',
      status: 'Status',
      action: 'Action',
      productNotFound: 'Product Not Found',
      saveChanges: 'Save Changes',
      backToList: 'Back To List',
      consentDetails: 'Consent Details',
      english: 'English',
      thai: 'Thai',
      number: 'No.',
      title: 'Title',
      content: 'Content',
      option: 'Option {{option_no}}',
      warningText: 'Warning text',
    },
    service: {
      serviceManagement: 'Service Management',
      id: 'ID',
      serviceId: 'Service ID',
      image: 'Image',
      name_en: 'English Name',
      name_th: 'Thai Name',
      short_desc_en: 'English Short Description',
      short_desc_th: 'Thai Short Description',
      description_en: 'English Description',
      description_th: 'Thai Description',
      status: 'Status',
      action: 'Action',
      serviceNotFound: 'Service Not Found',
      saveChanges: 'Save Changes',
      backToList: 'Back To List',
      products: 'Products',
      delete: 'Delete',
      selectProduct: 'Select product',
      add: 'Add',
    },
    customer: {
      customer: 'Customer',
      customerManagement: 'Customer Management',
      keyword: 'Keyword',
      keywordPlaceholder: 'Search by name, email, phone, ID card number',
      purchasedProduct: 'Purchased Product',
      selectProducts: 'Select products',
      lastPurchaseDate: 'Last Purchase Date',
      createDate: 'Create Date',
      updateDate: 'Update Date',
      id: 'ID',
      customerId: 'Customer ID',
      title: 'Title',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      gender: 'Gender',
      dateOfBirth: 'Date of Birth',
      nationality: 'Nationality',
      mobileNumber: 'Mobile Number',
      maritalStatus: 'Marital Status',
      weight: 'Weight',
      height: 'Height',
      occupation: 'Occupation',
      subOccupation: 'Sub Occupation',
      action: 'Action',
      customerNotFound: 'Customer Not Found',
      idCard: 'ID Card',
      passport: 'Passport',
      landlineNumber: 'Landline Number',
      language: 'Language',
      backToList: 'Back To List',
      orderDetails: 'Order Details',
    },
    order: {
      orderManagement: 'Order Management',
      keyword: 'Keyword',
      keywordPlaceholder: 'Search by name, email, phone, ID card number',
      selectProducts: 'Select products',
      selectCustomers: 'Select customers',
      orderDetails: 'Order Details',
      id: 'ID',
      orderId: 'Order ID',
      status: 'Status',
      product: 'Product',
      price: 'Price',
      expireDate: 'Policy Expiration Date',
      purchasedProductPlan: 'Purchased Product Plan',
      orderDate: 'Order Date',
      activeDate: 'Active Date',
      lastCoverageDate: 'Last Coverage Date',
      lastPaymentDate: 'Last Payment Date',
      nextPaymentDate: 'Next Payment Date',
      policyId: 'Policy ID',
      PAPolicy: 'PA Policy',
      healthPolicy: 'Health Policy',
      policyNumber: 'Policy Number',
      action: 'Action',
      noOrders: 'No Orders',
      backToList: 'Back To List',
      name_en: 'English Name',
      name_th: 'Thai Name',
      paymentAmount: 'Payment Amount',
      orderAddress: 'Order Address',
      address: 'Address',
      province: 'Province',
      district: 'District',
      subdistrict: 'Subdistrict',
      postcode: 'Postcode',
      email: 'Email',
      mobileNumber: 'Mobile Number',
    },
    thankYou: {
      thankYouPage: 'Thank You Page',
      thankYouPageDetails: 'Thank You Page Details',
      id: 'ID',
      type: 'Type',
      content_en: 'Content (English)',
      content_th: 'Content (Thai)',
      receivePolicy: 'Receive Policy',
      beCovered: 'Be Covered',
      nextStepContent_en: '{{nextStep}} Content (English)',
      nextStepContent_th: '{{nextStep}} Content (Thai)',
      action: 'Action',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      backToList: 'Back To List',
    },
  },
  errors: {
    required: '{{field}} is required',
  },
};
