import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import SurveyApi from '../../apis/survey';
import AccountApi from '../../apis/account';
import { surveyTypeId } from '../../constants';

interface IQuestion {
  answers: any;
  author: any;
  created_at: string;
  id: number;
  name: string;
  topic: number;
  type: number;
  updated_at: string;
}
interface ISurveyTemplateQuestion {
  id: number;
  question: IQuestion;
}
interface ISurveyTemplateDetail {
  author: any;
  description: string;
  id: number;
  name: string;
  survey_template_questions: ISurveyTemplateQuestion[];
  type: number;
}

interface ISurveyQuestion {
  code: string;
  data: {
    email: string;
    jwt_token: string;
    role: string;
    survey: any;
    user_info: any;
  } | null;
  is_succeed: boolean;
  message: string;
}

export interface ISurveyState {
  surveyList: any | null;
  surveyListIsShared: any | null;
  surveyDetail: any | null;
  surveyQuestion: ISurveyQuestion | null;
  selected: any;
  topicList: any | null;
  notRunList: any;
  surveyTemplatesList: any | null;
  surveyTemplatesDetail: ISurveyTemplateDetail | null;
  surveyPublicLink: string | null;
  statusRemindSurvey: boolean;
}

const initialState: ISurveyState = {
  surveyList: null,
  surveyListIsShared: null,
  surveyDetail: null,
  surveyQuestion: null,
  selected: null,
  topicList: null,
  notRunList: [],
  surveyTemplatesList: null,
  surveyTemplatesDetail: null,
  surveyPublicLink: null,
  statusRemindSurvey: false,
};

export const fetchTopicList = createAsyncThunk(
  'survey/fetch-topic-list',
  async () => {
    const { data: response } = await SurveyApi.getTopicList();
    return response;
  }
);

export const fetchSurveyIsShared = createAsyncThunk(
  'survey/fetch-survey/isShared',
  async ({id, page, isShared}: {id: number, page?: number, isShared?: boolean}) => {
    const { data: response } = await SurveyApi.getSurveyList(id, page, isShared);
    return response;
  }
);

export const fetchSurvey = createAsyncThunk(
  'survey/fetch-survey',
  async ({id, page}: {id: number, page?: number}) => {
    const { data: response } = await SurveyApi.getSurveyList(id, page);
    return response;
  }
);

export const fetchSurveyDetail = createAsyncThunk(
  'survey/fetch-survey-detail',
  async (id: number) => {
    const { data: response } = await SurveyApi.getSurveyDetail(id);
    return response;
  }
);

export const fetchNotRunList = createAsyncThunk(
  'survey/fetch-not-run-list',
  async (id: number) => {
    const { data: response } = await SurveyApi.getNotRunList(id);
    return response;
  }
);

export const exportNotRunList = createAsyncThunk(
  'survey/export-not-run-list',
  async (id: number) => {
    const { data: response } = await SurveyApi.exportNotRunList(id);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'nguoi-chua-thuc-hien.csv');
    document.body.appendChild(link);
    link.click();
  }
);

export const fetchSurveyTemplates = createAsyncThunk(
  'survey/fetch-survey-templates',
  async () => {
    const { data: response } = await SurveyApi.getSurveyTemplates();
    return response;
  }
);

export const fetchSurveyTemplatesDetail = createAsyncThunk(
  'survey/fetch-survey-templates-detail',
  async (id: number) => {
    const { data: response } = await SurveyApi.getSurveyTemplatesDetail(id);
    return response;
  }
);

export const createSurvey = createAsyncThunk(
  'survey/create-survey',
  async (payload: any) => {
    const { data: response } = await SurveyApi.createSurvey(payload);
    return response;
  }
);

export const updateSurvey = createAsyncThunk(
  'survey/update-survey',
  async ({ payload, id }: any) => {
    const { data: response } = await SurveyApi.updateSurvey(payload, id);
    return response;
  }
);

export const triggerPauseSurvey = createAsyncThunk(
  'accounts/update-survey',
  async ({ survey, id }: any) => {
    const newStatus = survey.status == surveyTypeId.PAUSED ? surveyTypeId.RUNNING : surveyTypeId.PAUSED;
    const payload = {
      status: newStatus,
    };
    const { data: response } = await SurveyApi.updateSurvey(payload, id);
    return response;
  }
);

export const deleteSurvey = createAsyncThunk(
  'accounts/delete-survey',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await SurveyApi.deleteSurvey(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const getSurveyQuestions = createAsyncThunk(
  'survey/get-survey-question',
  async (token: any) => {
    const { data: response } = await SurveyApi.checkSurveyToken(token);
    return response;
  }
);

export const getSurveyPublicQuestions = createAsyncThunk(
  'survey/get-survey-public-question',
  async (token: any) => {
    const { data: response } = await SurveyApi.checkSurveyPublicToken(token);
    return response;
  }
);

export const getSurveyPublicLink = createAsyncThunk(
  'survey/get-survey-public-link',
  async () => {
    const { data: response } = await SurveyApi.getSurveyPublicLink();
    return response.public_link;
  }
);

export const reminderUser = createAsyncThunk(
  'survey/reminder-user',
  async (id: number) => {
    const { data: response } = await SurveyApi.reminderUser(id);
    return response;
  }
);

export const submitAnswer = createAsyncThunk(
  'survey/submit-answer',
  async ({ payload, config }: any) => {
    const { data: response } = await SurveyApi.submitAnswer(payload, config);
    return response;
  }
);

export const shareSurvey = createAsyncThunk(
  'survey/share-survey',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await SurveyApi.shareSurvey(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    setSurveyPublicLink: (state, action: PayloadAction<any>) => {
      state.surveyPublicLink = action.payload;
    },
    setSurveyList: (state, action) => {
      state.surveyList.docs = action.payload;
    },
  },
  extraReducers: {
    [fetchSurvey.fulfilled as any]: (state, action) => {
      state.surveyList = action.payload;
    },
    [fetchSurveyIsShared.fulfilled as any]: (state, action) => {
      state.surveyListIsShared = action.payload;
    },
    [fetchSurveyDetail.fulfilled as any]: (state, action) => {
      state.surveyDetail = action.payload;
    },
    [fetchSurveyTemplates.fulfilled as any]: (state, action) => {
      state.surveyTemplatesList = action.payload;
    },
    [fetchSurveyTemplatesDetail.fulfilled as any]: (state, action) => {
      state.surveyTemplatesDetail = action.payload;
    },
    [fetchTopicList.fulfilled as any]: (state, action) => {
      state.topicList = action.payload;
    },
    [fetchNotRunList.fulfilled as any]: (state, action) => {
      state.notRunList = action.payload;
    },
    [getSurveyQuestions.fulfilled as any]: (state, action) => {
      state.surveyQuestion = action.payload;
    },
    [getSurveyPublicQuestions.fulfilled as any]: (state, action) => {
      state.surveyQuestion = action.payload;
    },
    [getSurveyPublicLink.fulfilled as any]: (state, action) => {
      state.surveyPublicLink = action.payload;
    },
    [submitAnswer.fulfilled as any]: (state, action) => {
      toast.success('Thành công !');
    },
    [reminderUser.pending as any]: (state) => {
      state.statusRemindSurvey = true;
    },
    [reminderUser.fulfilled as any]: (state) => {
      toast.success('Gửi mail nhắc nhở user thành công !');
      state.statusRemindSurvey = false;
    },
    [shareSurvey.fulfilled as any]: () => {
      toast.success('Chia sẻ thành công !');
    },
  },
});

export const {
  setSelected,
  setSurveyPublicLink,
  setSurveyList,
} = surveySlice.actions;

export default surveySlice.reducer;
