import { DEFAULT_ENDPOINT_SUFFIX } from '../constants';
import { httpClient } from '../utils/axios';

const SURVEY_ENDPOINT_SUFFIX = '/api/v1/surveys';
const SURVEY_TEMPLATES_ENDPOINT_SUFFIX = '/api/v1/survey-templates';
const TOPIC_ENDPOINT_SUFFIX = '/api/v1/questions/topics';
const SURVEY_ENDPOINT_USER = '/api/v1/user-survey-reports';

const SurveyApi = {
  getSurveyList: (id: number, page?: number, isShared?: boolean) => 
    httpClient.get(`${SURVEY_ENDPOINT_SUFFIX}?status=${id}&page=${page !== undefined ? page : ''}&limit=${20}&isShared=${isShared ? 1 : ''}`),
  reminderUser: (id: number) =>
    httpClient.post(`${SURVEY_ENDPOINT_SUFFIX}/${id}/remind`),
  getSurveyDetail: (id: number) =>
    httpClient.get(`${SURVEY_ENDPOINT_SUFFIX}/${id}`),
  getNotRunList: (id: number) =>
    httpClient.get(`${SURVEY_ENDPOINT_SUFFIX}/${id}/not-run-users`),
  exportNotRunList: (id: number) =>
    httpClient.get(`${SURVEY_ENDPOINT_SUFFIX}/${id}/not-run-users`, {
      responseType: 'blob',
    }),
  createSurvey: (payload: any) =>
    httpClient.post(`${SURVEY_ENDPOINT_SUFFIX}`, payload),
  updateSurvey: (payload: any, surveyId: number) =>
    httpClient.patch(`${SURVEY_ENDPOINT_SUFFIX}/${surveyId}`, payload),
  deleteSurvey: (surveyId: number) =>
    httpClient.delete(`${SURVEY_ENDPOINT_SUFFIX}/${surveyId}`),
  getTopicList: () => httpClient.get(TOPIC_ENDPOINT_SUFFIX),
  getSurveyTemplates: () =>
    httpClient.get(`${SURVEY_TEMPLATES_ENDPOINT_SUFFIX}`),
  getSurveyTemplatesDetail: (id: number) =>
    httpClient.get(`${SURVEY_TEMPLATES_ENDPOINT_SUFFIX}/${id}`),
  getSurveyPublicLink: () =>
    httpClient.get(`${SURVEY_ENDPOINT_SUFFIX}/init-public-link`),
  checkSurveyToken: (token: string) =>
    httpClient.post(`${DEFAULT_ENDPOINT_SUFFIX}/checkTokenRunSurvey`, token),
  checkSurveyPublicToken: (token: string) =>
    httpClient.post(
      `${DEFAULT_ENDPOINT_SUFFIX}/verify-token-run-survey-public`,
      token
    ),
  submitAnswer: (answers: any, config: any) =>
    httpClient.post(
      `${DEFAULT_ENDPOINT_SUFFIX}/survey-answer`,
      answers,
      config
    ),
  shareSurvey: (payload: any) =>
    httpClient.post(`${SURVEY_ENDPOINT_USER}`, payload),
};

export default SurveyApi;
