import IRoute from '../models/Route';
import async from '../components/Async';
import AppLayout from '../layouts/AppLayout';
import { Routes } from '../constants';

const HomePage = async(() => import('../pages/Home'));
const UserProfile = async(() => import('../pages/UserProfile'));
const ChangePassword = async(() => import('../pages/ChangePassword'));
const ManageSurvey = async(() => import('../pages/ManageSurvey'));
const ManageSurveyTemplates = async(
  () => import('../pages/ManageSurveyTemplates')
);
const PreviewSurvey = async(
  () => import('../pages/ManageSurveyTemplates/PreviewSurvey')
);
const UseTemplate = async(
  () => import('../pages/ManageSurveyTemplates/UseTemplate')
);
const ManageAccount = async(() => import('../pages/ManageAccount'));

const ManageSuggestion = async(() => import('../pages/ManageSuggestion'));

const ManageUser = async(() => import('../pages/ManageUser'));
const ManageCompany = async(() => import('../pages/ManageCompany'));
const UpdateUser = async(() => import('../pages/ManageUser/UpdateUser'));
const CreateUser = async(() => import('../pages/ManageUser/CreateUser'));
const ManageDepartment = async(() => import('../pages/ManageDepartment'));
const UpdateDepartment = async(
  () => import('../pages/ManageDepartment/UpdateDepartment')
);
const CreateDepartment = async(
  () => import('../pages/ManageDepartment/CreateDepartment')
);
const ManagePosition = async(() => import('../pages/ManagePosition'));
const UpdatePosition = async(
  () => import('../pages/ManagePosition/UpdatePosition')
);
const CreatePosition = async(
  () => import('../pages/ManagePosition/CreatePosition')
);
const Permission = async(() => import('../pages/Permission'));
const CreatePermission = async(
  () => import('../pages/Permission/CreatePermission')
);
const UpdatePermission = async(
  () => import('../pages/Permission/UpdatePermission')
);

const CreateSurvey = async(() => import('../pages/CreateSurvey'));
const EditSurvey = async(() => import('../pages/EditSurvey'));

const ManageReport = async(() => import('../pages/ManageReport'));
const ShowReport = async(() => import('../pages/ManageReport/ShowReport'));

const AdminReport = async(() => import('../pages/AdminReport'));

const appRoutes: IRoute[] = [
  {
    id: 'HomePage',
    path: '/',
    component: HomePage,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'Permission',
    path: '/manage-permissions',
    component: Permission,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'CreatePermission',
    path: '/manage-permissions/create-permission',
    component: CreatePermission,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'UpdatePermission',
    path: '/manage-permissions/:id',
    component: UpdatePermission,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'AdminReport',
    path: '/admin-report',
    component: AdminReport,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'UserProfile',
    path: '/user-profile',
    component: UserProfile,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ChangePassword',
    path: '/change-password',
    component: ChangePassword,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ManageSurvey',
    path: Routes.MANAGE_SURVEY,
    layout: AppLayout,
    component: ManageSurvey,
    admin: true,
  },
  {
    id: 'CreateSurvey',
    path: `${Routes.MANAGE_SURVEY}/new-survey`,
    layout: AppLayout,
    component: CreateSurvey,
    admin: true,
  },
  {
    id: 'EditSurvey',
    path: `${Routes.MANAGE_SURVEY}/edit-survey/:id`,
    component: EditSurvey,
    layout: AppLayout,
    children: null,
  },
  {
    id: 'ManageSurveyTemplates',
    path: Routes.MANAGE_SURVEY_TEMPLATES,
    layout: AppLayout,
    component: ManageSurveyTemplates,
    admin: true,
  },
  {
    id: 'PreviewSurvey',
    path: `${Routes.MANAGE_SURVEY_TEMPLATES}/:id`,
    layout: AppLayout,
    component: PreviewSurvey,
    admin: true,
  },
  {
    id: 'UseTemplate',
    path: `${Routes.MANAGE_SURVEY_TEMPLATES}/use-template/:id`,
    layout: AppLayout,
    component: UseTemplate,
    admin: true,
  },
  {
    id: 'ManageUser',
    path: Routes.MANAGE_USER,
    layout: AppLayout,
    component: ManageUser,
    admin: true,
  },
  {
    id: 'ManageCompany',
    path: Routes.MANAGE_COMPANY,
    layout: AppLayout,
    component: ManageCompany,
    admin: true,
  },
  {
    id: 'CreateUser',
    path: `${Routes.MANAGE_USER}/new-user`,
    layout: AppLayout,
    component: CreateUser,
    admin: true,
  },
  {
    id: 'UpdateUser',
    path: `${Routes.MANAGE_USER}/:id`,
    layout: AppLayout,
    component: UpdateUser,
    admin: true,
  },
  {
    id: 'ManageDepartment',
    path: Routes.MANAGE_DEPARTMENT,
    layout: AppLayout,
    component: ManageDepartment,
    admin: true,
  },
  {
    id: 'CreateDepartment',
    path: `${Routes.MANAGE_DEPARTMENT}/new-department`,
    layout: AppLayout,
    component: CreateDepartment,
    admin: true,
  },
  {
    id: 'UpdateDepartment',
    path: `${Routes.MANAGE_DEPARTMENT}/:id`,
    layout: AppLayout,
    component: UpdateDepartment,
    admin: true,
  },
  {
    id: 'ManagePosition',
    path: Routes.MANAGE_POSITION,
    layout: AppLayout,
    component: ManagePosition,
    admin: true,
  },
  {
    id: 'CreatePosition',
    path: `${Routes.MANAGE_POSITION}/new-position`,
    layout: AppLayout,
    component: CreatePosition,
    admin: true,
  },
  {
    id: 'UpdatePosition',
    path: `${Routes.MANAGE_POSITION}/:id`,
    layout: AppLayout,
    component: UpdatePosition,
    admin: true,
  },
  {
    id: 'ManageReport',
    path: Routes.MANAGE_REPORT,
    layout: AppLayout,
    component: ManageReport,
    admin: true,
  },
  {
    id: 'ReportDetail',
    path: `${Routes.MANAGE_REPORT}/:id`,
    layout: AppLayout,
    component: ShowReport,
    admin: true,
  },
  {
    id: 'ManageAccount',
    path: Routes.MANAGE_ACCOUNT,
    layout: AppLayout,
    component: ManageAccount,
    admin: true,
  },
  {
    id: 'ManageSuggestion',
    path: Routes.MANAGE_SUGGESTION,
    layout: AppLayout,
    component: ManageSuggestion,
    admin: true,
  },
];

export default appRoutes;
