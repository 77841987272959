import { httpClient } from '../utils/axios';

const POSITION_ENDPOINT_SUFFIX = '/api/v1/positions';

const PositionApi = {
  getPositionList: () => httpClient.get(`${POSITION_ENDPOINT_SUFFIX}`),
  createPosition: (payload: any) =>
    httpClient.post(`${POSITION_ENDPOINT_SUFFIX}`, payload),
  updatePosition: (payload: any, positionId: number) =>
    httpClient.patch(`${POSITION_ENDPOINT_SUFFIX}/${positionId}`, payload),
  deletePosition: (id: number) =>
    httpClient.delete(`${POSITION_ENDPOINT_SUFFIX}/${id}`),
};

export default PositionApi;
