import { DEFAULT_ENDPOINT_SUFFIX } from '../constants';
import { httpClient } from '../utils/axios';

const USER_ENDPOINT_SUFFIX = '/api/v1/users';

const UserApi = {
  getUserList: (payload?: any, page?: number) => httpClient.get(`${USER_ENDPOINT_SUFFIX}?page=${page ? page : ''}&limit=20`, payload),
  createUser: (payload: any) =>
    httpClient.post(`${USER_ENDPOINT_SUFFIX}`, payload),
  updateUser: (payload: any, userId: number) =>
    httpClient.patch(`${USER_ENDPOINT_SUFFIX}/${userId}`, payload),
  deleteUser: (userId: number) =>
    httpClient.delete(`${USER_ENDPOINT_SUFFIX}/${userId}`),

  importUser: (payload: any) =>
    httpClient.post(`${DEFAULT_ENDPOINT_SUFFIX}/import-users`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  exportUser: () =>
    httpClient.get(`${USER_ENDPOINT_SUFFIX}/export`, {
      responseType: 'blob',
    }),
};

export default UserApi;
