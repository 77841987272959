import { httpClient } from '../utils/axios';

const SUGGESTION_ENDPOINT_SUFFIX = '/api/v1/suggestions';

const SuggestionApi = {
  getSuggestionList: (page?: number) => httpClient.get(`${SUGGESTION_ENDPOINT_SUFFIX}?page=${page ? page : ''}&limit=20`),
  getSuggestionDetail: (id: number) =>
    httpClient.get(`${SUGGESTION_ENDPOINT_SUFFIX}/${id}`),
};

export default SuggestionApi;
