import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import DepartmentApi from '../../apis/department';

export interface IDepartment {
  company: {
    id: number;
    name: string;
  };
  name: string;
  id: number;
}

export interface IDepartmentState {
  departmentList: IDepartment[];
  selected: IDepartment | null;
}

const initialState: IDepartmentState = {
  departmentList: [],
  selected: null,
};

export const fetchDepartmentList = createAsyncThunk(
  'department/fetch-department-list',
  async () => {
    const { data: response } = await DepartmentApi.getDepartmentList();
    return response;
  }
);

export const createDepartment = createAsyncThunk(
  'department/create-department',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await DepartmentApi.createDepartment(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const updateDepartment = createAsyncThunk(
  'department/update-department',
  async ({ payload, callback }: any) => {
    try {
      const { name, company } = payload;
      const { data: response } = await DepartmentApi.updateDepartment(
        { name, company },
        payload.id
      );
      return response;
    } finally {
      callback();
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  'department/delete-department',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await DepartmentApi.deleteDepartment(
        payload.id
      );
      return response;
    } finally {
      callback();
    }
  }
);

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
  },
  extraReducers: {
    [fetchDepartmentList.fulfilled as any]: (state, action) => {
      state.departmentList = action.payload;
    },
    [createDepartment.fulfilled as any]: () => {
      toast.success('Tạo mới thành công !');
    },
    [updateDepartment.fulfilled as any]: () => {
      toast.success('Cập nhật thành công !');
    },
    [deleteDepartment.fulfilled as any]: () => {
      toast.success('Xóa thành công !');
    },
  },
});

export const { setSelected, setDepartmentList } = departmentSlice.actions;

export default departmentSlice.reducer;
