import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en';
import th from './translations/th';
import { AppEnv, Locale } from '../../constants';

export const initI18n = (locale: Locale) => {
  i18n.use(initReactI18next).init({
    lng: locale,
    fallbackLng: process.env.REACT_APP_DEFAULT_LOCALE,
    resources: {
      en: {
        translation: en,
      },
      th: {
        translation: th,
      },
    },

    debug: process.env.NODE_ENV === AppEnv.DEV,

    cache: {
      enabled: true,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
  });
};

// eslint-disable-next-line
export const switchLocale = (locale: Locale) => {
  i18n.changeLanguage(locale);
};
