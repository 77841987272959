import { httpClient } from '../utils/axios';

const DEPARTMENT_ENDPOINT_SUFFIX = '/api/v1/departments';

const DepartmentApi = {
  getDepartmentList: () => httpClient.get(`${DEPARTMENT_ENDPOINT_SUFFIX}`),
  createDepartment: (payload: any) =>
    httpClient.post(`${DEPARTMENT_ENDPOINT_SUFFIX}`, payload),
  updateDepartment: (payload: any, departmentId: number) =>
    httpClient.patch(`${DEPARTMENT_ENDPOINT_SUFFIX}/${departmentId}`, payload),
  deleteDepartment: (id: number) =>
    httpClient.delete(`${DEPARTMENT_ENDPOINT_SUFFIX}/${id}`),
};

export default DepartmentApi;
