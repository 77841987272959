import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import UserApi from '../../apis/user';

// export interface IAdminProduct {
//   id: number;
//   name_en: string;
//   name_th: string;
//   short_desc_en: string;
//   short_desc_th: string;
//   description_en: string;
//   description_th: string;
//   image: string;
//   status: number;
//   created_at: string;
//   updated_at: string;
// }

export interface IUserState {
  userList: any | null;
  selected: any | null;
}

const initialState: IUserState = {
  userList: null,
  selected: null,
};

export const fetchUserList = createAsyncThunk(
  'user/fetchUserList',
  async (page?: number) => {
    const { data: response } = await UserApi.getUserList(null,page);
    return response;
  }
);

export const fetchUserListByCompany = createAsyncThunk(
  'user/fetchUserListByCompany',
  async ({ payload }: any) => {
    const { data: response } = await UserApi.getUserList(payload);
    return response;
  }
);

export const importUser = createAsyncThunk(
  'user/import-user',
  async ({ payload }: any) => {
    const { data: response } = await UserApi.importUser(payload);
    return response;
  }
);

export const exportUser = createAsyncThunk('user/export-user', async () => {
  const { data: response } = await UserApi.exportUser();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'nguoi-dung.csv');
  document.body.appendChild(link);
  link.click();
});

export const getExampleFile = createAsyncThunk(
  'user/example-file',
  async () => {
    const url = process.env.REACT_APP_IMPORT_USERS_SAMPLE_FILE ? process.env.REACT_APP_IMPORT_USERS_SAMPLE_FILE : '';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'import_users_sample.xlsx');
    document.body.appendChild(link);
    link.click();
  }
);

export const createUser = createAsyncThunk(
  'user/create-user',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await UserApi.createUser(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/update-user',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await UserApi.updateUser(payload, payload.id);
      return response;
    } finally {
      callback();
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/delete-user',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await UserApi.deleteUser(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const changePermission = createAsyncThunk(
  'user/change-permission',
  async ({ payload, id, callback }: any) => {
    try {
      const { data: response } = await UserApi.updateUser(payload, id);
      return response;
    } finally {
      callback();
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setUserList: (state, action) => {
      state.userList.docs = action.payload;
    },
  },
  extraReducers: {
    [fetchUserList.fulfilled as any]: (state, action) => {
      state.userList = action.payload;
    },
    [fetchUserListByCompany.fulfilled as any]: (state, action) => {
      state.userList = action.payload;
    },
    [createUser.fulfilled as any]: () => {
      toast.success('Tạo mới thành công !');
    },
    [updateUser.fulfilled as any]: () => {
      toast.success('Cập nhật thành công !');
    },
    [deleteUser.fulfilled as any]: () => {
      toast.success('Xóa thành công !');
    },
    [changePermission.fulfilled as any]: () => {
      toast.success('Cập nhật thành công !');
    },
  },
});

export const { setSelected, setUserList } = userSlice.actions;

export default userSlice.reducer;
