import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useAppDispatch, useAppSelector } from '../../hooks';
import LanguageSelector from '../LanguageSelector';
import {
  Avatar,
  Box,
  Button,
  Menu as MuiMenu,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { setAdminLocale } from '../../redux/slices/globalSlice';
import styled from 'styled-components';
import { Locale, Path, Routes } from '../../constants';
import { useHistory } from 'react-router';
import { logout } from '../../redux/slices/authSlice';

type HeaderProps = {
  drawerWidth: number;
  open: boolean;
  toggleDrawer: () => void;
};

const Menu = styled(MuiMenu)`
  &&& {
    .MuiMenu-paper {
      top: 0;
      margin-top: 2%;
    }
  }
`;

const MenuButton = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.palette.common.gray};
  }
`;

const AvatarImg = styled(Avatar)`
  &&& {
    margin-left: 10px;
  }
`;

const Header = ({ open, drawerWidth, toggleDrawer }: HeaderProps) => {
  const useStyles = makeStyles((theme) => ({
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#fff',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      color: theme.palette.common.black,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    locale: { admin },
  } = useAppSelector((state) => state.global);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userInfo, enabledModules } = useAppSelector((state) => state.auth);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(setAdminLocale(Locale.ENG));
  }, []);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        ></Typography>
        {/* <Box mr={3}>
          <LanguageSelector
            value={admin}
            onChange={(event: React.ChangeEvent<any>) => {
              dispatch(setAdminLocale(event.target.value));
            }}
          />
        </Box> */}
        <MenuButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Typography>{(userInfo && userInfo?.email) || ''}</Typography>
          <AvatarImg
            aria-label="recipe"
            src={
              (userInfo && userInfo?.avatar) ||
              `${Path.IMAGES_PNG}/undraw_profile.svg`
            }
          ></AvatarImg>
        </MenuButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.push(`${Routes.HOME}user-profile`)}>
            Cập nhật thông tin
          </MenuItem>
          <MenuItem
            onClick={() => history.push(`${Routes.HOME}change-password`)}
          >
            Cập nhật mật khẩu
          </MenuItem>
          {enabledModules && enabledModules.includes('branch') && (
            <MenuItem onClick={() => history.push(`${Routes.MANAGE_COMPANY}`)}>
              Cập nhật thông tin công ty
            </MenuItem>
          )}
          {enabledModules && enabledModules.includes('user') && (
            <MenuItem onClick={() => history.push(`${Routes.MANAGE_USER}`)}>
              Quản lý user
            </MenuItem>
          )}
          {enabledModules && enabledModules.includes('department') && (
            <MenuItem
              onClick={() => history.push(`${Routes.MANAGE_DEPARTMENT}`)}
            >
              Quản lý phòng ban
            </MenuItem>
          )}
          {enabledModules && enabledModules.includes('position') && (
            <MenuItem onClick={() => history.push(`${Routes.MANAGE_POSITION}`)}>
              Quản lý vị trí
            </MenuItem>
          )}
          <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
