import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import globalReducer from '../slices/globalSlice';
import userReducer from '../slices/userSlice';
import departmentReducer from '../slices/departmentSlice';
import positionReducer from '../slices/positionSlice';
import surveyReducer from '../slices/surveySlice';
import reportReducer from '../slices/reportSlice';
import permissionReducer from '../slices/permissionSlice';
import accountReducer from '../slices/accountSlice';
import suggestionReducer from '../slices/suggestionSlice';

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  user: userReducer,
  department: departmentReducer,
  position: positionReducer,
  survey: surveyReducer,
  report: reportReducer,
  permission: permissionReducer,
  account: accountReducer,
  suggestion: suggestionReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['global', 'auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
