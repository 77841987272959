import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AccountApi from '../../apis/account';

export interface IAccountState {
  accountList: any | null;
  accountDetail: any | null;
  selected: any;
}

const initialState: IAccountState = {
  accountList: null,
  accountDetail: null,
  selected: null,
};

export const fetchAccount = createAsyncThunk(
  'accounts/fetch-accounts',
  async (page?: number) => {
    const { data: response } = await AccountApi.getAccountList(page);
    return response;
  }
);

export const createAccount = createAsyncThunk(
  'accounts/create-account',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await AccountApi.createAccount(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const updateAccount = createAsyncThunk(
  'accounts/update-account',
  async ({ payload, id }: any) => {
    const { data: response } = await AccountApi.updateAccount(payload, id);
    return response;
  }
);

export const triggerLockAccount = createAsyncThunk(
  'accounts/update-account',
  async ({ account, id }: any) => {
    const payload = {
      enabled: account.enabled,
    };
    const { data: response } = await AccountApi.updateAccount(payload, id);
    return response;
  }
);

export const fetchAccountDetail = createAsyncThunk(
  'accounts/fetch-account-detail',
  async (id: number) => {
    const { data: response } = await AccountApi.getAccountDetail(id);
    return response;
  }
);

export const deleteAccount = createAsyncThunk(
  'accounts/delete-account',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await AccountApi.deleteAccount(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    setAccountList: (state, action) => {
      state.accountList = action.payload;
    },
  },
  extraReducers: {
    [fetchAccount.fulfilled as any]: (state, action) => {
      state.accountList = action.payload;
    },
    [fetchAccountDetail.fulfilled as any]: (state, action) => {
      state.accountDetail = action.payload;
    },
  },
});

export const { setSelected, setAccountList } = accountSlice.actions;

export default accountSlice.reducer;
