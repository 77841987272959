import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import AuthApi from '../../apis/auth';

export interface ISuggestedList {
  department: Array<any>;
  position: Array<any>;
  category: Array<any>;
  scale: Array<any>;
}
export interface IAuthState {
  errMess: string | null;
  token: string | null;
  registerInfo: any;
  resetPasswordState: boolean;
  forgotPasswordState: boolean;
  registerState: boolean;
  registerConfirmState: boolean;
  suggestedList: ISuggestedList;
  userDepartment: any;
  userPosition: any;
  userInfo: any;
  isTokenExpired: boolean;
  citiesList: Array<any>;
  userCompany: any | null;
  enabledModules: any;
  managersList: Array<any>;
}

export interface LoginDetails {
  username: string;
  password: string;
}
interface LoginRequestProps {
  loginDetails: LoginDetails;
  callback: () => void | null;
}

interface EmailPasswordProps {
  payload: any;
  callback: () => void | null;
}

export const login = createAsyncThunk(
  'auth/login',
  async (
    { loginDetails, callback }: LoginRequestProps,
    { rejectWithValue }
  ) => {
    try {
      const { data: response } = await AuthApi.login(loginDetails);
      return response.token;
    } finally {
      callback();
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async ({ payload, callback }: EmailPasswordProps) => {
    try {
      const {
        data: { data },
      } = await AuthApi.register(payload);
      return data;
    } finally {
      callback();
    }
  }
);
export const forgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async ({ payload, callback }: EmailPasswordProps, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await AuthApi.forgotPassword(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    } finally {
      callback();
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async ({ payload, callback }: EmailPasswordProps) => {
    try {
      const {
        data: { data },
      } = await AuthApi.resetPassword(payload);
      return data;
    } finally {
      callback();
    }
  }
);

export const checkToken = createAsyncThunk(
  'auth/check-token',
  async (token: any) => {
    const { data: response } = await AuthApi.checkToken(token);
    return response;
  }
);

export const checkTokenRegister = createAsyncThunk(
  'auth/check-token-register',
  async (token: any) => {
    const { data: response } = await AuthApi.checkTokenRegister(token);
    return response;
  }
);

export const registerConfirm = createAsyncThunk(
  'auth/register-confirm',
  async ({ payload, callback }: any) => {
    try {
      const {
        data: { data },
      } = await AuthApi.confirmRegister(payload);
      return data;
    } finally {
      callback();
    }
  }
);

export const fetchSuggestedList = createAsyncThunk(
  'auth/suggested-list',
  async () => {
    const { data: response } = await AuthApi.fetchSuggestedList();
    return response;
  }
);

export const fetchCitiesList = createAsyncThunk(
  'auth/cities-list',
  async () => {
    const { data: response } = await AuthApi.fetchCitiesList();
    return response;
  }
);

export const getUserInfo = createAsyncThunk('auth/user-info', async () => {
  const { data: response } = await AuthApi.getUserInfo();
  return response;
});

export const getUserCompany = createAsyncThunk(
  'auth/user-company',
  async (id: number) => {
    const { data: response } = await AuthApi.getUserCompany(id);
    return response;
  }
);

export const getUserDepartment = createAsyncThunk(
  'auth/user-department',
  async () => {
    const { data: response } = await AuthApi.getUserDepartment();
    return response;
  }
);
export const getUserPosition = createAsyncThunk(
  'auth/user-position',
  async () => {
    const { data: response } = await AuthApi.getUserPosition();
    return response;
  }
);

export const getUserManager = createAsyncThunk(
  'auth/user-manager',
  async (id: number) => {
    const { data: response } = await AuthApi.getUserManager(id);
    return response;
  }
);

export const updateUserInfo = createAsyncThunk(
  'auth/update-user-info',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await AuthApi.updateUserInfo(payload);
      toast.success('Cập nhật thông tin thành công !');
      return response;
    } finally {
      callback();
    }
  }
);

export const updateUserCompany = createAsyncThunk(
  'auth/update-user-company',
  async ({ id, payload, callback }: any) => {
    try {
      const { data: response } = await AuthApi.updateUserCompany(id, payload);
      toast.success('Cập nhật thông tin thành công !');
      return response;
    } finally {
      callback();
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  'auth/update-user-password',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await AuthApi.updateUserPassword(payload);
      toast.success('Cập nhật mật khẩu thành công !');
      return response;
    } finally {
      callback();
    }
  }
);

export const updateUserImage = createAsyncThunk(
  'auth/update-user-image',
  async ({ payload }: any) => {
    const { data: response } = await AuthApi.updateUserImage(payload);
    return response;
  }
);
const initialState: IAuthState = {
  errMess: null,
  token: null,
  registerInfo: '',
  resetPasswordState: false,
  forgotPasswordState: false,
  registerState: false,
  registerConfirmState: false,
  suggestedList: {
    department: [],
    position: [],
    category: [],
    scale: [],
  },
  userInfo: null,
  userCompany: null,
  isTokenExpired: false,
  citiesList: [],
  userDepartment: [],
  userPosition: [],
  enabledModules: [],
  managersList: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setErrMess: (state, action: PayloadAction<string | null>) => {
      state.errMess = action.payload;
    },
    logout: (state) => {
      state.token = initialState.token;
      state.userInfo = initialState.userInfo;
    },
    setResetPasswordState: (state, action) => {
      state.resetPasswordState = action.payload;
    },
    setForgotPasswordState: (state, action) => {
      state.forgotPasswordState = action.payload;
    },
    setRegisterState: (state, action) => {
      state.registerState = action.payload;
    },
    setRegisterConfirmState: (state, action) => {
      state.registerConfirmState = action.payload;
    },
    setTokenState: (state, action) => {
      state.isTokenExpired = action.payload;
    },
    setUserImage: (state, action) => {
      state.userInfo.images = action.payload;
    },
  },
  extraReducers: {
    [login.fulfilled as any]: (state, action) => {
      state.token = action.payload;
    },
    [checkToken.fulfilled as any]: (state, action) => {
      state.registerInfo = action.payload.email;
    },
    [checkTokenRegister.fulfilled as any]: (state, action) => {
      state.registerInfo = action.payload;
    },
    [forgotPassword.fulfilled as any]: (state) => {
      state.forgotPasswordState = true;
    },
    [register.fulfilled as any]: (state) => {
      state.registerState = true;
    },
    [registerConfirm.fulfilled as any]: (state) => {
      state.registerConfirmState = true;
      state.registerInfo = '';
    },
    [resetPassword.fulfilled as any]: (state) => {
      state.resetPasswordState = true;
      state.registerInfo = '';
    },
    [fetchSuggestedList.fulfilled as any]: (state, action) => {
      state.suggestedList = action.payload;
    },
    [fetchCitiesList.fulfilled as any]: (state, action) => {
      state.citiesList = action.payload;
    },
    [getUserDepartment.fulfilled as any]: (state, action) => {
      state.userDepartment = action.payload;
    },
    [getUserManager.fulfilled as any]: (state, action) => {
      state.managersList = action.payload;
    },
    [getUserPosition.fulfilled as any]: (state, action) => {
      state.userPosition = action.payload;
    },
    [getUserInfo.fulfilled as any]: (state, action) => {
      state.userInfo = action.payload;
      state.enabledModules = action.payload.enabled_modules;
    },
    [getUserCompany.fulfilled as any]: (state, action) => {
      state.userCompany = action.payload;
    },
    [updateUserInfo.fulfilled as any]: (state, action) => {
      state.userInfo = action.payload;
    },
    [updateUserCompany.fulfilled as any]: (state, action) => {
      state.userCompany = action.payload;
    },
    [updateUserImage.fulfilled as any]: (state, action) => {
      state.userInfo.image = action.payload;
    },
  },
});

export const {
  setErrMess,
  logout,
  setResetPasswordState,
  setForgotPasswordState,
  setRegisterState,
  setRegisterConfirmState,
  setToken,
  setTokenState,
  setUserImage,
} = authSlice.actions;

export default authSlice.reducer;
