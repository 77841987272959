import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withWidth } from '@material-ui/core';
import styled from 'styled-components';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const DRAWER_WIDTH = 240;

const Root = styled.div`
  display: flex;
  max-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${DRAWER_WIDTH}px);
  margin-top: 64px;
  padding: 24px;
  overflow: auto;
`;

export interface IAppLayoutProps {
  children?: any;
  width?: any;
  [key: string]: any;
}

const AppLayout = ({ children }: IAppLayoutProps) => {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Root>
      <CssBaseline />
      <Sidebar
        drawerWidth={DRAWER_WIDTH}
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <Header
        drawerWidth={DRAWER_WIDTH}
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <Content>{children}</Content>
    </Root>
  );
};

export default withWidth()(AppLayout);
