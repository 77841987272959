import axios from 'axios';
import { toast } from 'react-toastify';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    toast.error(error.response.data.message);
    if (
      error.response.data.code === 401 &&
      window.location.pathname !== '/login' &&
      error.response.config.url !==
        '/api/v1/surveys/verify-token-run-survey-public'
    ) {
      localStorage.clear();
      window.location.href = '/login';
    }
  }
);
