import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import PositionApi from '../../apis/position';

export interface IPosition {
  company: {
    id: number;
    name: string;
  };
  name: string;
  id: number;
}

export interface IPositionState {
  positionList: IPosition[];
  selected: IPosition | null;
}

const initialState: IPositionState = {
  positionList: [],
  selected: null,
};

export const fetchPositionList = createAsyncThunk(
  'position/fetchPositionList',
  async () => {
    const { data: response } = await PositionApi.getPositionList();
    return response;
  }
);

export const createPosition = createAsyncThunk(
  'position/create-position',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await PositionApi.createPosition(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const updatePosition = createAsyncThunk(
  'position/update-position',
  async ({ payload, callback }: any) => {
    try {
      const { name, company } = payload;
      const { data: response } = await PositionApi.updatePosition(
        { name, company },
        payload.id
      );
      return response;
    } finally {
      callback();
    }
  }
);

export const deletePosition = createAsyncThunk(
  'position/delete-position',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await PositionApi.deletePosition(payload.id);
      return response;
    } finally {
      callback();
    }
  }
);

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setPositionList: (state, action) => {
      state.positionList = action.payload;
    },
  },
  extraReducers: {
    [fetchPositionList.fulfilled as any]: (state, action) => {
      state.positionList = action.payload;
    },
    [createPosition.fulfilled as any]: () => {
      toast.success('Tạo mới thành công !');
    },
    [updatePosition.fulfilled as any]: () => {
      toast.success('Cập nhật thành công !');
    },
    [deletePosition.fulfilled as any]: () => {
      toast.success('Xóa thành công !');
    },
  },
});

export const { setSelected, setPositionList } = positionSlice.actions;

export default positionSlice.reducer;
