import { Route, useHistory } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import useQuery from '../hooks/useQuery';
import { httpClient } from '../utils/axios';
import { Routes } from '../constants';
import { setToken } from '../redux/slices/authSlice';

const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  admin,
  ...rest
}: any) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const query = useQuery();

  const paramToken = query.get('t');
  if (paramToken) {
    dispatch(setToken(paramToken));
  }
  const { token: persistToken } = useAppSelector((state) => state.auth);
  const token = paramToken || persistToken;
  React.useEffect(() => {
    if (!token) {
      history.push(Routes.LOGIN);
    } else {
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, [token]);

  return Layout ? (
    <Layout>
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    </Layout>
  ) : (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  );
};

export default memo(ProtectedRoute);
