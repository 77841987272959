import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useHistory, useLocation } from 'react-router-dom';
import { Role, Routes } from '../../constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../hooks';

interface INavItemProps {
  icon: any; // eslint-disable-line
  label: string;
  to: string;
}

const NavItem = ({ icon, label, to }: INavItemProps) => {
  const history = useHistory();
  const location = useLocation();

  const redirect = () => {
    history.push(to);
  };

  return (
    <ListItem
      button
      selected={location.pathname.includes(to)}
      key={label}
      onClick={redirect}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

const IconDashboard = styled(DashboardIcon)`
  &&& {
    color: white;
  }
`;

const IconHome = styled(HomeOutlinedIcon)`
  &&& {
    color: white;
  }
`;

const IconReport = styled(AssessmentOutlinedIcon)`
  &&& {
    color: white;
  }
`;

const IconManageAccounts = styled(ManageAccountsIcon)`
  &&& {
    color: white;
  }
`;

const IconManageSuggestions = styled(AutoFixHighIcon)`
  &&& {
    color: white;
  }
`;

export const MainListItems = () => {
  const { t } = useTranslation();
  const { userInfo } = useAppSelector((state) => state.auth);

  return (
    <>
      {(userInfo?.role === Role.ROLE_PLATFORM_STAFF ||
        userInfo?.role === Role.ROLE_PLATFORM_EDITOR ||
        userInfo?.role === Role.ROLE_PLATFORM_ADMIN) && (
        <NavItem label="Dashboard" icon={<IconHome />} to={Routes.HOME} />
      )}
      <NavItem
        label="Quản lý khảo sát"
        icon={<IconDashboard />}
        to={Routes.MANAGE_SURVEY}
      />
      <NavItem
        label="Thống kê khảo sát"
        icon={<IconReport />}
        to={Routes.MANAGE_REPORT}
      />
      {userInfo?.role !== Role.ROLE_USER && (
        <NavItem
          label="Kho mẫu khảo sát"
          icon={<IconDashboard />}
          to={Routes.MANAGE_SURVEY_TEMPLATES}
        />
      )}
      {(userInfo?.role === Role.ROLE_PLATFORM_STAFF ||
        userInfo?.role === Role.ROLE_PLATFORM_EDITOR ||
        userInfo?.role === Role.ROLE_PLATFORM_ADMIN) && (
        <NavItem
          label="Quản lý account"
          icon={<IconManageAccounts />}
          to={Routes.MANAGE_ACCOUNT}
        />
      )}
      {(userInfo?.role === Role.ROLE_PLATFORM_STAFF ||
        userInfo?.role === Role.ROLE_PLATFORM_EDITOR ||
        userInfo?.role === Role.ROLE_PLATFORM_ADMIN) && (
        <NavItem
          label="Quản lý Đề xuất"
          icon={<IconManageSuggestions />}
          to={Routes.MANAGE_SUGGESTION}
        />
      )}
    </>
  );
};
