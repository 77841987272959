export default {
  title: 'Thai title',
  locale: {
    en: 'English',
    th: 'Thailand',
  },
  menu: {
    home: 'หน้าแรก',
    balance: 'ยอดคงเหลือ',
    careShop: 'แคร์ช็อป',
    profile: 'โปรไฟล์',
  },
  common: {
    error: 'ผิดพลาด',
    adminSection: 'ส่วนผู้ดูแลระบบ',
    logout: 'ออกจากระบบ',
    filter: 'กรอง',
    reset: 'รีเซ็ต',
    exportAsCsv: 'ส่งออกเป็น CSV',
    male: 'ชาย',
    female: 'หญิง',
    rowsPerPage: 'แถวต่อหน้า',
    of: 'จาก',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    update: 'อัพเดท',
    cancel: 'ยกเลิก',
    editSuccessfully: 'แก้ไขสำเร็จ!',
    buyNow: 'ซื้อเลย!',
    startFreeTrial: 'เริ่มทดลองใช้ฟรี',
    oneMonth: '1 เดือน',
    moreDetail: 'รายละเอียดเพิ่มเติม',
    next: 'ถัดไป',
    goBackToHomepage: 'กลับสู่หน้าหลัก',
    confirm: 'ยืนยัน',
    no: 'ไม่ยืนยัน',
    bahtPerYear: 'บาท/ปี',
    bahtPerMonth: 'บาท/เดือน',
  },
  page: {
    home: {
      products: 'ผลิตภัณฑ์',
      services: 'บริการ',
    },
    checkoutRejection: { refuseInsurance: 'ปฏิเสธการรับประกันภัย' },
    thankYou: {
      dear: 'เรียน คุณ{{name}}',
      thankYouText:
        'Thank you for choosing rabbit finance. Find below the summary and next steps for your policy underwriting:',
      thankYouTextFreeTrial:
        'Thank you, your 30 day free trial started. Find below the summary and next steps.',
      showCoverageDetail: 'แสดงรายละเอียดความคุ้มครอง',
      nextSteps: 'ขั้นตอนต่อไป',
      totalPrice: 'ราคาสุทธิ',
      beCovered: 'ความคุ้มครองของคุณ',
      receivePolicy: 'การรับกรมธรรม์',
      textIncludeTax: '*ราคานี้ รวมภาษีมูลค่าเพิ่ม 7% และอากรแสตมป์',
      goBackToHomepage: 'กลับสู่หน้าหลัก',
    },
    serviceDetail: {
      time: 'ครั้ง',
    },
    productDetail: {
      coverage: 'ความคุ้มครอง',
      condition: 'เงื่อนไข',
    },
    healthQuestions: {
      healthQuestions: 'คำถามสุขภาพ',
    },
    checkout: {
      checkout: 'ตรวจสอบรายการสั่งซื้อ',
      personalInformation: 'กรุณากรอกข้อมูลส่วนตัว',
      title: 'คำนำหน้า',
      name: 'ชื่อจริง',
      surname: 'นามสกุล',
      idCardNumber: 'หมายเลขบัตรประชาชน',
      phoneNumber: 'เบอร์โทรศัพท์',
      emailAddress: 'อีเมล',
      dateOfBirth: 'วัน/เดือน/ปีเกิด',
      address: 'ที่อยู่',
      province: 'จังหวัด',
      district: 'เขต/อำเภอ',
      subDistrict: 'แขวง/ตำบล',
      postcode: 'รหัสไปรษณีย์',
      additionalQuestion: 'กรุณาตอบคำถามเพิ่มเติม',
      weightKg: 'น้ำหนัก (กก.)',
      heightCm: 'ส่วนสูง (ซม.)',
      occupation: 'อาชีพหลัก',
      subOccupation: 'อาชีพย่อย',
      confirm: 'ยืนยัน',
      addCardDetails: 'กรอกรายละเอียดบัตรเดบิต/บัตรเครดิต',
      selectCard: 'เลือกบัตร',
      newCard: 'การ์ดใหม่',
      cardNo: 'บัตรหมายเลข',
      nameOfCardHolder: 'ชื่อผู้ถือบัตร',
      expiryDate: 'วันหมดอายุ (MM/YYYY)',
      cvv: 'CVV (รหัส 3 หลักหลังบัตร)',
      saveCardDetails: 'บันทึกรายละเอียดบัตร',
      setToDefault: 'ตั้งเป็นค่าเริ่มต้น',
      shortDetails:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    checkoutResult: {
      paymentUnsuccessful: 'The Payment was Unsuccessful',
      paymentUnsuccessfulSubtitle:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    },
    consent: {
      consent: 'การขอความยินยอม',
    },
    lastCoverage: {
      lastCoverageDate: 'วันสุดท้ายที่ได้รับความคุ้มครอง',
      description: 'กรุณากรอกวันสิ้นสุดความคุ้มครอง',
      placeHolderDateField:
        'กรุณาเลือกวัน/เดือน/ปีที่ระบุในกรมธรรม์ว่าเป็นวันสิ้นสุดความคุ้มครอง',
    },
    confirmCancel: {
      confirmCancellation: 'ยืนยันการยกเลิกกรมธรรม์',
      description:
        'ตรวจสอบยอดเงินคืนค่าเบี้ยประกันภัย ที่คำนวณจากวันที่สิ้นสุดความคุ้มครอง',
    },
    cancellationSuccessful: {
      dear: 'เรียน คุณ{{name}}',
      cancelText:
        'การยกเลิกกรมธรรม์ของคุณเสร็จสิ้นแล้ว คุณสามารถอ่านรายละเอียดการยกเลิกกรมธรรม์ด้านล่างนี้',
    },
    currentCoverages: {
      pageTitle: 'ความคุ้มครองปัจจุบัน',
      confirmPolicy:
        'หากยกเลิกกรมธรรม์ดังกล่าว คุณจะไม่ได้รับความคุ้มครองด้านล่างนี้อีกต่อไป',
      confirmSection: 'คุณต้องการยืนยันการยกเลิกกรมธรรม์ใช่หรือไม่',
    },
    cancellationReasons: {
      title: 'เหตุผลการขอยกเลิกกรมธรรม์',
      reason: 'ทำไมคุณถึงต้องการยกเลิกกรมธรรม์',
      reasonDetail: 'กรุณาระบุรายละเอียดเพิ่มเติม',
      placeHolderCombobox: 'โปรดบอกเราว่าเกิดอะไรขึ้น',
      placeHolderTextField: 'กรุณาชี้แจงรายละเอียดเพิ่มเติม',
    },
    mySubscriptions: {
      yourPurchase: 'คำสั่งซื้อของคุณ',
      mySubscriptions: 'การสมัครของฉัน',
      myCancelledProducts: 'รายการที่ถูกยกเลิกของฉัน',
      product: 'ผลิตภัณฑ์',
      status: 'สถานะ',
      nextPayment: 'การชำระเงินครั้งต่อไป',
      total: 'รวม',
      renew: 'ต่ออายุ',
      view: 'ดูรายละเอียด',
    },
    mySubscriptionDetail: {
      status: 'สถานะ',
      policyNumber: 'เลขที่กรมธรรม์',
      PAPolicy: 'กรมธรรม์อุบัติเหตุ',
      healthPolicy: 'กรมธรรม์สุขภาพ',
      startCoverageDate: 'วันเริ่มต้นความคุ้มครอง',
      endCoverageDate: 'วันสิ้นสุดความคุ้มครอง',
      lastPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งล่าสุด',
      nextPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งต่อไป',
      total: 'รวม',
      refund: 'ยอดเงินคืนค่าเบี้ยฯ',
      cancel: 'ยกเลิก',
    },
  },
  admin: {
    product: {
      productManagement: 'การจัดการผลิตภัณฑ์',
      id: 'ไอดี',
      productId: 'รหัสสินค้า',
      image: 'ภาพ',
      name_en: 'ชื่อภาษาอังกฤษ',
      name_th: 'ชื่อไทย',
      short_desc_en: 'คำอธิบายสั้น ๆ ภาษาอังกฤษ',
      short_desc_th: 'คำอธิบายโดยย่อ',
      description_en: 'คำอธิบายภาษาอังกฤษ',
      description_th: 'คำอธิบายภาษาไทย',
      status: 'สถานะ',
      action: 'ดูรายละเอียด',
      productNotFound: 'ไม่พบสินค้า',
      saveChanges: 'บันทึกการเปลี่ยนแปลง',
      backToList: 'กลับไปที่รายการ',
      consentDetails: 'รายละเอียดการยินยอม',
      english: 'ภาษาอังกฤษ',
      thai: 'ไทย',
      number: 'จำนวน',
      title: 'หัวข้อ',
      content: 'เนื้อหา',
      option: 'ตัวเลือกที่ {{option_no}}',
      warningText: 'ข้อความเตือน',
    },
    service: {
      serviceManagement: 'การจัดการบริการ',
      id: 'ไอดี',
      serviceId: 'รหัสบริการ',
      image: 'ภาพ',
      name_en: 'ชื่อภาษาอังกฤษ',
      name_th: 'ชื่อไทย',
      short_desc_en: 'คำอธิบายสั้น ๆ ภาษาอังกฤษ',
      short_desc_th: 'คำอธิบายโดยย่อ',
      description_en: 'คำอธิบายภาษาอังกฤษ',
      description_th: 'คำอธิบายภาษาไทย',
      status: 'สถานะ',
      action: 'ดูรายละเอียด',
      serviceNotFound: 'ไม่พบบริการ',
      saveChanges: 'บันทึกการเปลี่ยนแปลง',
      backToList: 'กลับไปที่รายการ',
      products: 'สินค้า',
      delete: 'ลบ',
      selectProduct: 'เลือกสินค้า',
      add: 'เพิ่ม',
    },
    customer: {
      customer: 'ลูกค้า',
      customerManagement: 'การจัดการลูกค้า',
      keyword: 'คำสำคัญ',
      keywordPlaceholder: 'ค้นหาตามชื่อ อีเมล เบอร์โทร เลขบัตรประชาชน',
      purchasedProduct: 'สินค้าที่ซื้อ',
      selectProducts: 'เลือกสินค้า',
      lastPurchaseDate: 'วันที่ซื้อล่าสุด',
      createDate: 'วันที่สร้าง',
      updateDate: 'อัพเดทวันที่',
      id: 'ไอดี',
      customerId: 'รหัสลูกค้า',
      title: 'สมญา',
      name: 'ชื่อ',
      firstName: 'ชื่อจริง',
      lastName: 'นามสกุล',
      email: 'อีเมล',
      gender: 'เพศ',
      dateOfBirth: 'วันเกิด',
      nationality: 'สัญชาติ',
      mobileNumber: 'เบอร์มือถือ',
      maritalStatus: 'สถานภาพการสมรส',
      weight: 'น้ำหนัก',
      height: 'ส่วนสูง',
      occupation: 'อาชีพ',
      subOccupation: 'อาชีพย่อย',
      action: 'ดูรายละเอียด',
      customerNotFound: 'ไม่พบลูกค้า',
      idCard: 'บัตรประจำตัวประชาชน',
      passport: 'หนังสือเดินทาง',
      landlineNumber: 'หมายเลขโทรศัพท์พื้นฐาน',
      language: 'ภาษา',
      backToList: 'กลับไปที่รายการ',
      orderDetails: 'รายละเอียดการสั่งซื้อ',
    },
    order: {
      orderManagement: 'การจัดการคำสั่งซื้อ',
      keyword: 'คำสำคัญ',
      keywordPlaceholder: 'ค้นหาตามชื่อ อีเมล เบอร์โทร เลขบัตรประชาชน',
      selectProducts: 'เลือกสินค้า',
      selectCustomers: 'เลือกลูกค้า',
      orderDetails: 'รายละเอียดการสั่งซื้อ',
      id: 'ไอดี',
      orderId: 'รหัสคำสั่งซื้อ',
      status: 'สถานะ',
      product: 'ผลิตภัณฑ์',
      price: 'ราคา',
      expireDate: 'วันหมดอายุกรมธรรม์',
      purchasedProductPlan: 'แผนผลิตภัณฑ์ที่ซื้อ',
      orderDate: 'วันสั่ง',
      activeDate: 'วันที่ใช้งาน',
      lastCoverageDate: 'วันสุดท้ายที่ได้รับความคุ้มครอง',
      lastPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งล่าสุด',
      nextPaymentDate: 'วันกำหนดชำระเบี้ยฯครั้งต่อไป',
      policyId: 'รหัสกรมธรรม์',
      PAPolicy: 'กรมธรรม์อุบัติเหตุ',
      healthPolicy: 'กรมธรรม์สุขภาพ',
      policyNumber: 'เลขที่กรมธรรม์',
      action: 'ดูรายละเอียด',
      noOrders: 'ไม่มีคำสั่ง',
      backToList: 'กลับไปที่รายการ',
      name_en: 'ชื่อภาษาอังกฤษ',
      name_th: 'ชื่อไทย',
      paymentAmount: 'จำนวนเงินที่ชำระ',
      orderAddress: 'ที่อยู่ในการสั่งซื้อ',
      address: 'ที่อยู่',
      province: 'จังหวัด',
      district: 'อำเภอ',
      subdistrict: 'ตำบล',
      postcode: 'รหัสไปรษณีย์',
      email: 'อีเมล',
      mobileNumber: 'เบอร์มือถือ',
    },
    thankYou: {
      thankYouPage: 'ขอบคุณเพจ',
      thankYouPageDetails: 'ขอบคุณรายละเอียดหน้า',
      id: 'ไอดี',
      type: 'พิมพ์',
      content_en: 'เนื้อหา (ภาษาอังกฤษ)',
      content_th: 'เนื้อหา (ภาษาไทย)',
      receivePolicy: 'รับนโยบาย',
      beCovered: 'ถูกปกคลุม',
      nextStepContent_en: 'เนื้อหา {{nextStep}} (ภาษาอังกฤษ)',
      nextStepContent_th: 'เนื้อหา {{nextStep}} (ภาษาไทย)',
      action: 'ดูรายละเอียด',
      createdAt: 'สร้างที่',
      updatedAt: 'ปรับปรุงที่',
      backToList: 'กลับไปที่รายการ',
    },
  },
  errors: {
    required: '{{field}} is required',
  },
};
