export enum Locale {
  ENG = 'en',
  THAI = 'th',
}

export enum AppEnv {
  DEV = 'development',
  PROD = 'production',
}

export const DEFAULT_ENDPOINT_SUFFIX = '/api/v1';

export enum Path {
  IMAGES_PNG = '/images/png',
}

export enum Routes {
  HOME = '/',
  PRODUCTS = '/products',
  MANAGE_SURVEY = '/manage-surveys',
  MANAGE_SURVEY_TEMPLATES = '/manage-survey-templates',
  MANAGE_USER = '/manage-users',
  MANAGE_ACCOUNT = '/manage-accounts',
  MANAGE_SUGGESTION = '/manage-suggestions',
  MANAGE_COMPANY = '/manage-company',
  MANAGE_DEPARTMENT = '/manage-departments',
  MANAGE_POSITION = '/manage-positions',
  MANAGE_PERMISSION = '/manage-permissions',
  MANAGE_REPORT = '/manage-reports',
  RUN_SURVEY = '/run-survey',

  HOMEPAGE = '/',
  LOGIN = '/login',
}

export enum Role {
  ROLE_PLATFORM_ADMIN = 'ROLE_PLATFORM_ADMIN',
  ROLE_PLATFORM_EDITOR = 'ROLE_PLATFORM_EDITOR',
  ROLE_PLATFORM_STAFF = 'ROLE_PLATFORM_STAFF',
  ROLE_COMPANY_OWNER = 'ROLE_COMPANY_OWNER',
  ROLE_COMPANY_EDITOR = 'ROLE_COMPANY_EDITOR',
  ROLE_BRANCH_OWNER = 'ROLE_BRANCH_OWNER',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_USER = 'ROLE_USER',
}

export enum userPermissionId {
  PLATFORM_ADMIN = 0,
  PLATFORM_EDITOR = 6,
  PLATFORM_STAFF = 1,
  COMPANY_OWNER = 2,
  COMPANY_EDITOR = 7,
  BRANCH_OWNER = 3,
  MANAGER = 4,
  USER = 5,
}

export const userPermission = [
  { id: userPermissionId.PLATFORM_ADMIN, label: 'VNS Super Admin', value: Role.ROLE_PLATFORM_ADMIN },
  { id: userPermissionId.PLATFORM_EDITOR, label: 'VNS Editor', value: Role.ROLE_PLATFORM_EDITOR },
  { id: userPermissionId.PLATFORM_STAFF, label: 'VNS Admin', value: Role.ROLE_PLATFORM_STAFF },
  { id: userPermissionId.COMPANY_OWNER, label: 'Account công ty', value: Role.ROLE_COMPANY_OWNER },
  { id: userPermissionId.BRANCH_OWNER, label: 'Chi nhánh', value: Role.ROLE_BRANCH_OWNER },
  { id: userPermissionId.MANAGER, label: 'Quản lý', value: Role.ROLE_MANAGER },
  { id: userPermissionId.USER, label: 'Người dùng', value: Role.ROLE_USER },
  { id: userPermissionId.COMPANY_EDITOR, label: 'Editor công ty', value: Role.ROLE_COMPANY_EDITOR },
];

export const userModules = [
  { id: 1, label: 'Công ty', value: 'App\\Entity\\Company' },
  { id: 2, label: 'Chi nhánh', value: 'App\\Entity\\Branch' },
  { id: 3, label: 'Ngành nghề', value: 'App\\Entity\\Category' },
  { id: 4, label: 'Quy mô', value: 'App\\Entity\\Scale' },
  { id: 5, label: 'Câu hỏi', value: 'App\\Entity\\Question' },
  { id: 6, label: 'Vị trí', value: 'App\\Entity\\Position' },
  { id: 7, label: 'Phòng ban', value: 'App\\Entity\\Department' },
  { id: 8, label: 'Quản lý khảo sát', value: 'App\\Entity\\Survey' },
  { id: 9, label: 'Khảo sát mẫu', value: 'App\\Entity\\SurveyTemplate' },
  { id: 10, label: 'Người dùng', value: 'App\\Entity\\User' },
  { id: 11, label: 'Báo cáo', value: 'App\\Entity\\Report' },
];

export const selectionQuestion = [
  {
    id: 1,
    label: 'Mặt cười',
  },
  {
    id: 2,
    label: 'Thanh trượt',
  },
  {
    id: 3,
    label: 'Trắc nghiệm',
  },
  {
    id: 4,
    label: 'Tùy chọn',
  },
  {
    id: 5,
    label: 'Tự luận',
  },
  {
    id: 6,
    label: 'Kết hợp',
  },
];

export enum surveyTypeId {
  NOT_RUNNING = 0,
  RUNNING = 1,
  DRAFT = 2,
  COMPLETED = 3,
  PAUSED = 4,
}

export const surveyType = [
  { id: surveyTypeId.RUNNING, label: 'Đang chạy' },
  { id: surveyTypeId.PAUSED, label: 'Tạm dừng' },
  { id: surveyTypeId.DRAFT, label: 'Nháp' },
  { id: surveyTypeId.COMPLETED, label: 'Kết thúc' },
  { id: surveyTypeId.NOT_RUNNING, label: 'Chưa đến lịch' },
];

export const surveyTarget = [
  {
    id: 1,
    label: 'Tất cả',
  },
  {
    id: 2,
    label: 'Phòng ban',
  },
  {
    id: 3,
    label: 'Nhân viên',
  },
  {
    id: 4,
    label: 'Địa chỉ Email',
  },
];

export const surveyTime = [
  { id: 1, label: 'Lặp lại theo tuần' },
  { id: 2, label: 'Lặp lại theo tháng' },
  { id: 3, label: 'Khảo sát 1 lần duy nhất' },
];

export const weeklyTime = [
  { id: 1, label: 'Thứ 2', value: 'Monday' },
  { id: 2, label: 'Thứ 3', value: 'Tuesday' },
  { id: 3, label: 'Thứ 4', value: 'Wednesday' },
  { id: 4, label: 'Thứ 5', value: 'Thursday' },
  { id: 5, label: 'Thứ 6', value: 'Friday' },
  { id: 6, label: 'Thứ 7', value: 'Saturday' },
];
export const weeklyTimeEnd = [
  { id: 1, label: '1', value: 1 },
  { id: 2, label: '2', value: 2 },
  { id: 3, label: '3', value: 3 },
  { id: 4, label: '4', value: 4 },
  { id: 5, label: '5', value: 5 },
  { id: 6, label: '6', value: 6 },
  { id: 7, label: '7', value: 7 },
];

export const monthlyTime = [
  { id: 1, label: 'Ngày 1' },
  { id: 2, label: 'Ngày 2' },
  { id: 3, label: 'Ngày 3' },
  { id: 4, label: 'Ngày 4' },
  { id: 5, label: 'Ngày 5' },
  { id: 6, label: 'Ngày 6' },
  { id: 7, label: 'Ngày 7' },
  { id: 8, label: 'Ngày 8' },
  { id: 9, label: 'Ngày 9' },
  { id: 10, label: 'Ngày 10' },
  { id: 11, label: 'Ngày 11' },
  { id: 12, label: 'Ngày 12' },
  { id: 13, label: 'Ngày 13' },
  { id: 14, label: 'Ngày 14' },
  { id: 15, label: 'Ngày 15' },
  { id: 16, label: 'Ngày 16' },
  { id: 17, label: 'Ngày 17' },
  { id: 18, label: 'Ngày 18' },
  { id: 19, label: 'Ngày 19' },
  { id: 20, label: 'Ngày 20' },
  { id: 21, label: 'Ngày 21' },
  { id: 22, label: 'Ngày 22' },
  { id: 23, label: 'Ngày 23' },
  { id: 24, label: 'Ngày 24' },
  { id: 25, label: 'Ngày 25' },
  { id: 26, label: 'Ngày 26' },
  { id: 27, label: 'Ngày 27' },
  { id: 28, label: 'Ngày 28' },
  { id: 29, label: 'Ngày 29' },
  { id: 30, label: 'Ngày 30' },
  { id: 31, label: 'Ngày 31' },
];

export const monthlyTimeEnd = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
  { id: 8, label: '8' },
  { id: 9, label: '9' },
  { id: 10, label: '10' },
  { id: 11, label: '11' },
  { id: 12, label: '12' },
  { id: 13, label: '13' },
  { id: 14, label: '14' },
  { id: 15, label: '15' },
  { id: 16, label: '16' },
  { id: 17, label: '17' },
  { id: 18, label: '18' },
  { id: 19, label: '19' },
  { id: 20, label: '20' },
  { id: 21, label: '21' },
  { id: 22, label: '22' },
  { id: 23, label: '23' },
  { id: 24, label: '24' },
  { id: 25, label: '25' },
  { id: 26, label: '26' },
  { id: 27, label: '27' },
  { id: 28, label: '28' },
  { id: 29, label: '29' },
  { id: 30, label: '30' },
  { id: 31, label: '31' },
];
