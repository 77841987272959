import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ReportApi from '../../apis/report';

export interface IReportState {
  surveyReport: any | null;
  adminReport: any | null;
  isFetching: boolean;
}

const initialState: IReportState = {
  surveyReport: null,
  adminReport: null,
  isFetching: false,
};

export const fetchReportDetail = createAsyncThunk(
  'report/fetch-report-detail',
  async (id: number) => {
    const { data: response } = await ReportApi.fetchReportDetail(id);
    return response;
  }
);

export const fetchReportNIKKODetail = createAsyncThunk(
  'report/fetch-report-nikko-detail',
  async ({ id, startDate, endDate }: any) => {
    const { data: response } = await ReportApi.fetchReportNikkoDetail(
      id,
      startDate,
      endDate
    );
    return response;
  }
);

export const fetchAdminTemplateDetail = createAsyncThunk(
  'report/fetch-admin-template-detail',
  async (id: any) => {
    const { data: response } = await ReportApi.fetchAdminTemplateReport(id);
    return response;
  }
);

export const fetchAdmReportDetail = createAsyncThunk(
  'report/fetch-adm-report-detail',
  async ({ id, startDate, endDate }: any) => {
    const { data: response } = await ReportApi.fetchAdmReportDetail(
      id,
      startDate,
      endDate
    );
    return response;
  }
);

export const fetchAdminReportDetail = createAsyncThunk(
  'report/fetch-admin-report-detail',
  async ({ type, period, start, length }: any) => {
    const { data: response } = await ReportApi.fetchAdminReportDetail(
      type,
      period,
      start,
      length
    );
    return response;
  }
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchReportDetail.pending as any]: (state) => {
      state.isFetching = true;
    },
    [fetchReportDetail.fulfilled as any]: (state, action) => {
      state.surveyReport = action.payload;
      state.isFetching = false;
    },
    [fetchReportNIKKODetail.pending as any]: (state) => {
      state.isFetching = true;
    },
    [fetchReportNIKKODetail.fulfilled as any]: (state, action) => {
      state.surveyReport = action.payload;
      state.isFetching = false;
    },
    [fetchAdminTemplateDetail.pending as any]: (state) => {
      state.isFetching = true;
    },
    [fetchAdminTemplateDetail.fulfilled as any]: (state, action) => {
      state.surveyReport = action.payload;
      state.isFetching = false;
    },
    [fetchAdmReportDetail.pending as any]: (state) => {
      state.isFetching = true;
    },
    [fetchAdmReportDetail.fulfilled as any]: (state, action) => {
      state.surveyReport = action.payload;
      state.isFetching = false;
    },
    [fetchAdminReportDetail.pending as any]: (state) => {
      state.isFetching = true;
    },
    [fetchAdminReportDetail.fulfilled as any]: (state, action) => {
      state.adminReport = action.payload;
      state.isFetching = false;
    },
  },
});
export default reportSlice.reducer;
