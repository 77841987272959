import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locale } from '../../constants';

export interface IThemeState {
  theme: number;
  locale: { user: Locale; admin: Locale };
  isNewUser: boolean;
  redirectUrl: string | null;
}

const initialState: IThemeState = {
  theme: 0,
  locale: {
    user: (process.env.REACT_APP_DEFAULT_LOCALE as Locale) || Locale.THAI,
    admin: (process.env.REACT_APP_DEFAULT_LOCALE as Locale) || Locale.THAI,
  },
  isNewUser: false,
  redirectUrl: null,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<number>) => {
      state.theme = action.payload;
    },
    setUserLocale: (state, action: PayloadAction<Locale>) => {
      state.locale.user = action.payload;
    },
    setAdminLocale: (state, action: PayloadAction<Locale>) => {
      state.locale.admin = action.payload;
    },
    setNewUser: (state, action) => {
      state.isNewUser = action.payload;
    },
    setUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
  },
});

export const {
  setTheme,
  setUserLocale,
  setAdminLocale,
  setNewUser,
  setUrl,
} = globalSlice.actions;

export default globalSlice.reducer;
