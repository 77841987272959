import { httpClient } from '../utils/axios';

const PERMISSION_ENDPOINT_SUFFIX = '/api/v1/access-controls';

const PermissionApi = {
  getPermissionList: () => httpClient.get(`${PERMISSION_ENDPOINT_SUFFIX}`),
  getPermissionDetail: (id: number) =>
    httpClient.get(`${PERMISSION_ENDPOINT_SUFFIX}/${id}`),
  createPermission: (payload: any) =>
    httpClient.post(`${PERMISSION_ENDPOINT_SUFFIX}`, payload),
  updatePermission: (payload: any, permissionId: number) =>
    httpClient.patch(`${PERMISSION_ENDPOINT_SUFFIX}/${permissionId}`, payload),
  deletePermission: (id: number) =>
    httpClient.delete(`${PERMISSION_ENDPOINT_SUFFIX}/${id}`),
};

export default PermissionApi;
