import { DEFAULT_ENDPOINT_SUFFIX } from '../constants';
import { httpClient } from '../utils/axios';

const ACCOUNT_ENDPOINT_SUFFIX = '/api/v1/accounts';

const AccountApi = {
  getAccountList: (page?: number) => httpClient.get(`${ACCOUNT_ENDPOINT_SUFFIX}?page=${page ? page : ''}&limit=20`),
  getAccountDetail: (id: number) =>
    httpClient.get(`${ACCOUNT_ENDPOINT_SUFFIX}/${id}`),
  createAccount: (payload: any) =>
    httpClient.post(`${ACCOUNT_ENDPOINT_SUFFIX}`, payload),
  updateAccount: (payload: any, accountId: number) =>
    httpClient.patch(`${ACCOUNT_ENDPOINT_SUFFIX}/${accountId}`, payload),
  deleteAccount: (accountId: number) =>
    httpClient.delete(`${ACCOUNT_ENDPOINT_SUFFIX}/${accountId}`),
};

export default AccountApi;
