import { string } from 'yup';
import { httpClient } from '../utils/axios';

const REPORT_ENDPOINT_SUFFIX = '/api/v1/report';
const ADMIN_REPORT_ENDPOINT_SUFFIX = '/api/v1/admin-report';
const ADMIN_ENDPOINT_SUFFIX = '/api/v1/adm-report';
const ReportApi = {
  fetchReportDetail: (id: any) =>
    httpClient.get(`${REPORT_ENDPOINT_SUFFIX}/${id}`),
  fetchReportNikkoDetail: (id: number, startDate: string, endDate: string) =>
    httpClient.get(
      `${REPORT_ENDPOINT_SUFFIX}/${id}?startDate=${startDate}&endDate=${endDate}`
    ),
  fetchAdminReportDetail: (
    type: string,
    period: string,
    start: string,
    length: number
  ) =>
    httpClient.get(
      `${ADMIN_REPORT_ENDPOINT_SUFFIX}?type=${type}&period=${period}&start=${start}&length=${length}`
    ),
  fetchAdmReportDetail: (
    id: number,
    startDate: string,
    endDate: string,
  ) =>
    httpClient.get(
      `${ADMIN_ENDPOINT_SUFFIX}/surveys/${id}?startDate=${startDate}&endDate=${endDate}`
    ),
  fetchAdminTemplateReport: (id: any) =>
    httpClient.get(`${ADMIN_ENDPOINT_SUFFIX}/surveys/${id}`),
  reportSuggestionClick: (id: any) => {
    httpClient.post(`${REPORT_ENDPOINT_SUFFIX}/${id}/suggestion-click`);
  },
};

export default ReportApi;
