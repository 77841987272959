import { httpClient } from '../utils/axios';

const AUTH_ENDPOINT_SUFFIX = '/api/v1';

const AuthApi = {
  login: (body: any) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/getToken`, body),
  register: (email: string) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/register`, email),
  confirmRegister: (payload: any) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/active-user`, payload),
  forgotPassword: (email: string) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/request-reset-password`, email),
  resetPassword: (email: string) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/reset-password`, email),
  checkToken: (token: any) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/verify-reset-token`, token),
  checkTokenRegister: (token: any) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/verify-register-token`, token),
  fetchSuggestedList: () =>
    httpClient.get(
      `${AUTH_ENDPOINT_SUFFIX}/category-scale-department-position`
    ),
  fetchCitiesList: () => httpClient.get(`${AUTH_ENDPOINT_SUFFIX}/cities`),
  getUserInfo: () => httpClient.get(`${AUTH_ENDPOINT_SUFFIX}/user-info`),
  getUserCompany: (id: number) =>
    httpClient.get(`${AUTH_ENDPOINT_SUFFIX}/companys/${id}`),
  getUserDepartment: () =>
    httpClient.get(`${AUTH_ENDPOINT_SUFFIX}/departments`),
  getUserPosition: () => httpClient.get(`${AUTH_ENDPOINT_SUFFIX}/positions`),
  getUserManager: (userId: number) =>
    httpClient.get(`${AUTH_ENDPOINT_SUFFIX}/users/${userId}/managers`),
  updateUserInfo: (payload: any) =>
    httpClient.patch(`${AUTH_ENDPOINT_SUFFIX}/my`, payload),
  updateUserCompany: (id: number, payload: any) =>
    httpClient.patch(`${AUTH_ENDPOINT_SUFFIX}/companys/${id}`, payload),
  updateUserPassword: (payload: any) =>
    httpClient.patch(`${AUTH_ENDPOINT_SUFFIX}/change-password`, payload),
  updateUserImage: (payload: any) =>
    httpClient.post(`${AUTH_ENDPOINT_SUFFIX}/images`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
};

export default AuthApi;
