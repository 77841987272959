import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import PermissionApi from '../../apis/permission';

export interface IPermission {
  user: any;
  action: string;
  id: number;
  resource: string;
  resource_name: string;
}

export interface IPermissionState {
  permissionList: IPermission[];
  selected: any | null;
}

const initialState: IPermissionState = {
  permissionList: [],
  selected: null,
};

export const fetchPermissionList = createAsyncThunk(
  'permission/fetchPermissionList',
  async () => {
    const { data: response } = await PermissionApi.getPermissionList();
    return response;
  }
);

export const fetchPermissionDetail = createAsyncThunk(
  'permission/fetchPermissionDetail',
  async (id: number) => {
    const { data: response } = await PermissionApi.getPermissionDetail(id);
    return response;
  }
);

export const createPermission = createAsyncThunk(
  'permission/create-permission',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await PermissionApi.createPermission(payload);
      return response;
    } finally {
      callback();
    }
  }
);

export const updatePermission = createAsyncThunk(
  'permission/update-permission',
  async ({ payload, id, callback }: any) => {
    try {
      const { data: response } = await PermissionApi.updatePermission(
        payload,
        id
      );
      return response;
    } finally {
      callback();
    }
  }
);

export const deletePermission = createAsyncThunk(
  'permission/delete-permission',
  async ({ payload, callback }: any) => {
    try {
      const { data: response } = await PermissionApi.deletePermission(
        payload.id
      );
      return response;
    } finally {
      callback();
    }
  }
);

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setPermissionList: (state, action) => {
      state.permissionList = action.payload;
    },
  },
  extraReducers: {
    [fetchPermissionList.fulfilled as any]: (state, action) => {
      state.permissionList = action.payload;
    },
    [createPermission.fulfilled as any]: () => {
      toast.success('Tạo mới thành công !');
    },
    [updatePermission.fulfilled as any]: () => {
      toast.success('Cập nhật thành công !');
    },
    [deletePermission.fulfilled as any]: () => {
      toast.success('Xóa thành công !');
    },
  },
});

export const { setSelected, setPermissionList } = permissionSlice.actions;

export default permissionSlice.reducer;
