import { rgba } from 'polished';

export enum Color {
  BLACK = '#3a3b45',
  BLACK_LIGHT = '#4F4B66',
  WHITE = '#FFF',
  GREY = '#5a5c69',
  GREY_LIGHT = '#5c5b5b',
  GREY_LIGHTER = '#7E7E7E',
  DARK_BLUE = '#4e73df',
  BLUE = '#1067cc',
  BLUE_WHITE_LIGHT = '#EDF2FF',
  BLUE_WHITE_LIGHTER = '#F2F3FA',
  BLUE_WHITE = '#E8EFFF',
  BLUE_LIGHTER = '#DEE8FF',
  BLUE_LIGHT = '#9db7d9',
  BLUE_GREY = '#97aac3',
  BLUE_GREY_LIGHT = '#ebeef3',
  BLUE_GREY_LIGHTER = '#e2e3ed',
  ORANGE = '#f58125',
  ORANGE_LIGHT = '#FD9000',
  GREEN_LIGHT = '#26DBCF',
  RED = '#fd0000',
  BLUE_LIGHT_NAVY = '#167AFF',
  GREY_BLACK = '#6B6A6B',
  GREY_BLACK_LIGHT = '#555555',
  GREY_LIGHT_NAVY = '#C2C3C7',
  SWEET_PINK = '#f59493',
  GRAY = '#858796',
}

const vnsVariant = {
  name: 'vnsColor',
  palette: {
    common: {
      black: Color.BLACK,
      blackLight: Color.BLACK_LIGHT,
      blue: Color.BLUE,
      blueLighter: Color.BLUE_LIGHTER,
      blueGrey: Color.BLUE_GREY,
      blueWhiteLight: Color.BLUE_WHITE_LIGHT,
      darkBlue: Color.DARK_BLUE,
      white: Color.WHITE,
      lightGreen: Color.GREEN_LIGHT,
      red: Color.RED,
      grey: Color.GREY,
      greyLighter: Color.GREY_LIGHTER,
      blueLightNavy: Color.BLUE_LIGHT_NAVY,
      greyBlack: Color.GREY_BLACK,
      greyBlackLight: Color.GREY_BLACK_LIGHT,
      greyLightNavy: Color.GREY_LIGHT_NAVY,
      sweetPink: Color.SWEET_PINK,
      gray: Color.GRAY,
    },
    primary: {
      main: Color.DARK_BLUE,
      contrastText: Color.WHITE,
    },
    secondary: {
      main: Color.ORANGE,
      light: Color.ORANGE_LIGHT,
      contrastText: Color.WHITE,
    },
    info: {
      white: rgba(Color.BLUE_LIGHTER, 0),
      light: rgba(Color.BLUE_LIGHTER, 0.3),
      main: Color.BLUE_LIGHTER,
      dark: Color.BLUE_LIGHT,
    },
    disabled: {
      main: Color.BLUE_GREY_LIGHTER,
    },
    background: {
      main: Color.BLUE_WHITE_LIGHTER,
    },
    text: {
      primary: Color.BLACK_LIGHT,
    },
    divider: Color.GREY,
    navigation: {
      main: Color.BLUE_GREY,
      contrastText: Color.WHITE,
      floatBackground: rgba(Color.BLUE_WHITE, 0.9),
      border: Color.BLUE_GREY_LIGHT,
    },
  },
};

const variants = [vnsVariant];

export default variants;
